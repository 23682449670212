<template>
  <div id="yzMap"></div>
</template>
<script>
import MapInit from '@/views/njall/components/TMap/MapInit.js';

export default {
  props: {
    mapPoint: {
      type: Array
    },
    GasStationPoint: {
      type: Array,
      require: true
    }
  },
  data () {
    return {
      zoom: 12,
      map: null
    };
  },
  mounted () {
    setTimeout(() => {
      this.roadMap();
    }, 1000);
    console.log(this.GasStationPoint);
  },
  methods: {
    roadMap () {
      MapInit.init()
        .then(T => {
          this.T = T;
          const imageURL =
            'http://t0.tianditu.gov.cn/img_w/wmts?SERVICE=WMTS&REQUEST=GetTile&VERSION=1.0.0&LAYER=img&STYLE=default&TILEMATRIXSET=w&FORMAT=tiles&TILEMATRIX={z}&TILEROW={y}&TILECOL={x}&tk=c9cd7951db1ea5b311aae41f5c0d60e4';
          const lay = new T.TileLayer(imageURL, { minZoom: 1, maxZoom: 18 });
          //   const lay2 = new T.TileLayer(imageURLT, { minZoom: 1, maxZoom: 18 });

          const config = { layers: [ lay ] };
          this.map = new T.Map('yzMap', config);
          this.map.addControl(window.TMAP_SATELLITE_MAP);

          const storedObject = JSON.parse(localStorage.getItem('key'));
          this.map.centerAndZoom(
            new T.LngLat(storedObject.longitude, storedObject.latitude),
            14
          );
          this.map.addEventListener('click', this.MapClick);
          // 添加标注

          const marker = new T.Marker(
            new T.LngLat(storedObject.longitude, storedObject.latitude)
          );

          this.map.addOverLay(marker);

          var infoWin1 = new T.InfoWindow();
          var sContent = `<div> 名称:${ storedObject.name }</div>`;
          infoWin1.setContent(sContent);
          marker.addEventListener('click', function () {
            marker.openInfoWindow(infoWin1);
          });

          // 将标注添加到地图中

          const Gasmarker = new T.Marker(
            new T.LngLat(
              +this.GasStationPoint[0]?.lonlat.split(' ')[0],
              +this.GasStationPoint[0]?.lonlat.split(' ')[1]
            )
          );
          this.map.addOverLay(Gasmarker);
          var infoWin2 = new T.InfoWindow();

          var sContent1 = `<div'>
          名称：${ this.GasStationPoint[0]?.name || '暂无名称' }<br>
           电话 : ${ this.GasStationPoint[0]?.phone || '暂无信息' } <br> 地址：${
            this.GasStationPoint[0]?.address
} `;
          infoWin2.setContent(sContent1);
          Gasmarker.addEventListener('click', function () {
            Gasmarker.openInfoWindow(infoWin2);
          });
          // 将标注添加到地图中

          const Gasmarker1 = new T.Marker(
            new T.LngLat(
              +this.GasStationPoint[1]?.lonlat.split(' ')[0],
              +this.GasStationPoint[1]?.lonlat.split(' ')[1]
            )
          );
          this.map.addOverLay(Gasmarker1);
          var infoWin3 = new T.InfoWindow();
          var sContent2 = `<div'>   名称：${
            this.GasStationPoint[1]?.name || '暂无名称'
          }<br> 电话 : ${
            this.GasStationPoint[1]?.phone || '暂无信息'
          } <br> 地址：${ this.GasStationPoint[1]?.address } `;
          infoWin3.setContent(sContent2);
          Gasmarker1.addEventListener('click', function () {
            Gasmarker1.openInfoWindow(infoWin3);
          }); // 将标注添加到地图中

          const Gasmarker2 = new T.Marker(
            new T.LngLat(
              +this.GasStationPoint[2]?.lonlat.split(' ')[0],
              +this.GasStationPoint[2]?.lonlat.split(' ')[1]
            )
          );
          this.map.addOverLay(Gasmarker2);
          var infoWin4 = new T.InfoWindow();

          var sContent3 = `<div'>    名称：${
            this.GasStationPoint[2]?.name || '暂无名称'
          }<br>电话 : ${
            this.GasStationPoint[2]?.phone || '暂无信息'
          } <br> 地址：${ this.GasStationPoint[2]?.address } `;
          infoWin4.setContent(sContent3);
          Gasmarker2.addEventListener('click', function () {
            Gasmarker2.openInfoWindow(infoWin4);
          }); // 将标注添加到地图中

          const Gasmarker3 = new T.Marker(
            new T.LngLat(
              +this.GasStationPoint[3]?.lonlat.split(' ')[0],
              +this.GasStationPoint[3]?.lonlat.split(' ')[1]
            )
          );
          this.map.addOverLay(Gasmarker3);
          var infoWin5 = new T.InfoWindow();

          var sContent4 = `   名称：${
            this.GasStationPoint[3]?.name || '暂无名称'
          }<br><div'> 电话 : ${
            this.GasStationPoint[3]?.phone || '暂无信息'
          } <br> 地址：${ this.GasStationPoint[3]?.address } `;
          infoWin5.setContent(sContent4);
          Gasmarker3.addEventListener('click', function () {
            Gasmarker3.openInfoWindow(infoWin5);
          }); // 将标注添加到地图中

          const Gasmarker4 = new T.Marker(
            new T.LngLat(
              +this.GasStationPoint[4]?.lonlat.split(' ')[0],
              +this.GasStationPoint[4]?.lonlat.split(' ')[1]
            )
          );
          this.map.addOverLay(Gasmarker4);
          var infoWin6 = new T.InfoWindow();

          var sContent5 = `<div'>
          名称：${ this.GasStationPoint[4]?.name || '暂无名称' }<br>
          电话 : ${ this.GasStationPoint[4]?.phone || '暂无信息' } <br> 地址：${
            this.GasStationPoint[4]?.address
} `;
          infoWin6.setContent(sContent5);
          Gasmarker4.addEventListener('click', function () {
            Gasmarker4.openInfoWindow(infoWin6);
          }); // 将标注添加到地图中
        })
        .catch();
    },
    MapClick () {
      this.$listeners.btn(true, 'map');
    }
  }
  // init() {
  //   return new Promise((resolve, reject) => {
  //     // 如果已加载直接返回
  //     if (window.T) {
  //       console.log('地图脚本初始化成功...')
  //       resolve(window.T)
  //       reject('error')
  //     }
  //   })
  // }
};
</script>

<style lang="scss" scoped>
@function companyW($num) {
  @return ($num * 100)/1920;
}
@function companyH($num) {
  @return ($num * 100)/1080;
}
#yzMap {
  width: companyW(940vw);
  height:76%;
}
</style>
