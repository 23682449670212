export const stateData = [

  {
    id: 1,
    srcName: require('@/assets/njImgs/njs.png'),
    data: 1,
    unit: '家',
    introduce: '农机维修合作点',
  },
  {
    id: 2,
    srcName: require('@/assets/njImgs/ljzymj.png'),
    data: 4,
    unit: '台',
    introduce: '在修农具',
  },
  {
    id: 3,
    srcName: require('@/assets/njImgs/zycs.png'),
    data: 20,
    unit: '次',
    introduce: '累计维修',
  },
  {
    id: 4,
    srcName: require('@/assets/njImgs/njCount.png'),
    data: 2,
    unit: '家',
    introduce: '待修农具',
  },
];
export const MidData = [
  {
    id: 1,
    title: '维修点站',
    data: 1,
    unit: '家',
  },
  {
    id: 2,
    title: '待修机具',
    data: '5',
    unit: '台',
  },
  {
    id: 3,
    title: '在修机具',
    data: '4',
    unit: '台',
  },
  {
    id: 4,
    title: '累计维修',
    data: '2',
    unit: '次',
  },
];
export const Listdata = [
  { repairStatus:'正在维修',name:'苏伟-拖拉机-xxxxx',question:'启动不了',price:'待结算',time:'正在维修...',btnTitle:'查看详情' }
];
export { btData };
const btData = [
  { id: 'njserve', title: '农机总览' },
  { id: 'zyserve', title: '作业服务' },
  { id: 'njdd', title: '农机调度' },
  { id: 'njwx', title: '农机维修' },
];
