import request from '@/utils/request.js';
// import axios from 'axios';
export function getRepairShop(params) {
  return request({
    url:'machineryRepairShop/get',
    method:'get',
    params
  });
}

export function getMachinery(params) {
  return request({
    url:'/machineryRepairOrder/get',
    method:'get',
    params
  });
}


export function getCount(params) {
  return request({
    url:'/machineryRepairShop/getCount',
    method:'get',
    params
  });
}
