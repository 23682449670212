<template>
  <div class="titleTop">
    <div class="TitleModule">
      <img :src="titleSrc" alt="" />
      <div>{{ titleName }}</div>
    </div>
    <slot></slot>
  </div>
</template>

<script>
export default {
  props: {
    titleSrcName: {
      type: String,
      default: '',
    },
    titleName: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      titleSrc: require(`@/assets/njImgs/${ this.titleSrcName }.png`),
    };
  },
};
</script>

<style lang="scss" scoped>
@function companyW($num) {
  @return ($num * 100)/1920;
}
@function companyH($num) {
  @return ($num * 100)/1080;
}
.titleTop {
  margin-bottom: companyH(10px);
  .TitleModule {
    z-index: 2;
    position: relative;
    img {
      width: companyW(422vw);
      height: companyH(39vh);
    }
    div {
      position: absolute;
      top: companyH(12vh);
      left: companyW(41vw);
      height: companyH(16vh);
      font-size: companyH(20vh);
      font-weight: bold;
      color: #dfeef3;
    }
  }
}
</style>
