<template>
  <div class="BoxOne" @click="maskchange">
    <div class="title">{{ boxData.title }}</div>
    <img src="@/assets/njImgs/njMidwk.png" alt="" />
    <div class="contentBox">
      <div>{{ boxData.data }}</div>
      <div>&nbsp;{{ boxData.unit }}</div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    boxData: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {};
  },
  methods: {
    maskchange() {
      this.$emit('changeMaskd', { id: this.boxData.id, typeof: 'left' });
    },
  },
};
</script>

<style lang="scss" scoped>
@function companyW($num) {
  @return ($num * 100)/1920;
}
@function companyH($num) {
  @return ($num * 100)/1080;
}
* {
  font-family: Source Han Sans CN;
  color: #fff;
  font-size: companyW(20vw);
}
.BoxOne {
  height: companyH(59vh);
  cursor: pointer;
  position: relative;
  width: companyW(208vw);
  line-height: companyH(59vh);

  img {
    width: companyW(208vw);
    height: companyH(59vh);
    position: absolute;
    z-index: 1;
    left: 0;
    top: 0;
  }
  .title {
    position: absolute;
    left: 50%;
    top: companyH(-31vh);
    transform: translateX(-50%);
    font-size: companyH(18vh);

    font-weight: 700;
    color: #ffe7df;
  }
  .contentBox {
    display: flex;
    align-items: center;
    justify-content: center;
    div {
      font-size: companyW(30vw);
z-index: 999;
      margin-top: companyH(5vh);
      font-family: Source Han Sans SC;
      font-weight: 500;
      color: #ffffff;
    }
  }
}
</style>
