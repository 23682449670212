<template>
  <div class="njServe">
    <Title
      class="njServiceTitle"
      njTitle="NjImg"
      typeOf="njImgs"
      :title="address + `数字农机`"
    ></Title>
    <div class="ContentBox">
      <!-- 左侧模块 -->
      <div class="ContentLeft">
        <!-- 农机左侧模块顶部 -->
        <MkContent titleSrcName="njTitle" titleName="基本信息">
          <div class="njTopBox">
            <div
              v-for="v in stateData"
              :key="v.id"
              class="njOne"

            >
              <img :src="v.srcName" alt="" />
              <div style="display: flex; flex-direction: column">
                <div class="njOne-top">
                  <div class="njOneFontL">{{ v.data }}</div>
                  <div class="njOneFont">&nbsp;{{ v.unit }}</div>
                </div>
                <div class="njOne-bottom">{{ v.introduce }}</div>
              </div>
            </div>
          </div>
        </MkContent>
        <!-- 农机左侧模块底部 -->
        <MkContent
          titleSrcName="njTitle"
          titleName="维修情况"
          style="margin-top: 20px"
        >
          <div class="njTopBox" style="padding: 0">
            <LeftCont :propOptions="njwxOptions"></LeftCont>
          </div>
        </MkContent>
        <MkContent
          titleSrcName="njTitle"
          titleName="维修监控"
          style="margin-top: 20px"
        >
          <div class="njTopBox" style="padding: 0">
            <LeftCont ></LeftCont>
          </div>
        </MkContent>
      </div>
      <!-- 中间部分 -->
      <div class="ContentMiddle">
        <MidBox
          v-for="v in MidData"
          :key="v.id"
          :boxData="v"
          style="margin: 0 companyW(8vw)"
          @changeMaskd="changeMas"
        ></MidBox>
        <TMap
          :mapPoint="mapPoint"
          class="tMap"
          :GasStationPoint="GasStationPoint"
        ></TMap>
      </div>

      <!-- 右侧部分 -->
      <div class="ContentRight">
        <div class="njTopBox">
          <MkContent titleSrcName="njTitle" titleName="历史维修记录">
            <div v-for="(v, i) in Listdata" :key="i">
              <BoxOne :ListDat="v"></BoxOne>
            </div>
          </MkContent>
        </div>
      </div>
    </div>
    <BottomNav
      :navData="btData"
      class="BottomNav"
      njTitle="navBottomImg"
      typeOf="njImgs"
    ></BottomNav>

    <MaskBox
      :changeMask="showMask"
      :mainTable="TableTitle"
      @getMask="closeMask"
      :TanleContent="TanleContent"

    >

    </MaskBox>
  </div>
</template>

<script>
import LeftCont from './components/njdd/LeftBox.vue';
import RightCont from './components/njzl/RightContent.vue';
// 每页标头
import Title from '@/views/drying/components/title.vue';
import MkContent from '@/views/njall/components/ModelBg.vue';
import BoxOne from '@/views/njall/components/njwxList/NjOne.vue';
// 中间每个小标题
import MidBox from '@/views/njall/components/MiddleBox.vue';
// 底部导航栏
import BottomNav from '@/views/njall/components/BottomNav.vue';
// 提示蒙层
import MaskBox from '@/views/njall/components/njwxDia.vue';
import NjMaskImg from '@/views/njall/components/njzl/NjzlMaskImg.vue';
import MidChart from '@/views/njall/components/njzl/ToolChart.vue';
// mock数据
import * as mockData from '@/views//njall/mock/njwx/njwxMock.js';
import * as njwxmock from '@/views/njall/mock/njwx/njwxChart.js';
import * as options from '@/views/njall/mock/njToolMock.js';
//地图
import TMap from '@/views/njall/components/TMap/njwxMap.vue';
import { getCount, getMachinery,getRepairShop } from '@/api/njApi/njwx.js';

export default {
  components: {
    BoxOne,
    BottomNav,
    MidChart,
    NjMaskImg,
    Title,
    LeftCont,
    RightCont,
    MkContent,
    MidBox,
    MaskBox,
    TMap
  },
  data () {
    return {
      total:10,

      address: '',
      stateParams: {
        title: '正常',
        count: 46
      },
      TableTitle: [],
      isJbxx: 'jbxx',
      ...options,
      ...njwxmock,
      showMask: false,
      ...mockData,
      // Location:'浙江省金华市义乌市上佛线佛堂镇农业双强示范区指挥部',
      mapPoint: [ 119.9989, 29.18799 ],
      GasStationPoint: [],

    };
  },
  mounted () {
    this.address = localStorage.getItem('address');
    setTimeout(() => {
      // this.openDiaMap();
    }, 500);
    this.searchMap();
  },
  created () {
    // this.openDiaMap();
    this.getgetCount();
    this.getgetMachinery();
  },
  methods: {
    async showDetail (id, typeofs) {
      console.log(typeofs, '看看');
      if (typeofs == 'left') {
        this.isJbxx = 'jbxx';
        if(id===1){
          await this.getRepairShopp();

          this.showMask = true;
        }
      } else {
        this.isJbxx = '';
      }
      console.log(this.isJbxx);
    },
    async getgetMachinery () {
      const { data } = await getMachinery();
      console.log(data);

      this.Listdata=data.records;
    },
    async getRepairShopp(){
      const data = await getRepairShop();
      console.log(data);
      this.TanleContent=data.data;
    },
    async getgetCount () {
      const { data } = await getCount();
      console.log(data);
      data.forEach(item => {
        if (item.status == 0) {
          this.stateData[0].data = item.count;
          this.MidData[0].data = item.count;
        } else if (item.status == 1) {
          this.stateData[1].data = item.count;
          this.MidData[1].data = item.count;
        } else if (item.status == 2) {
          this.stateData[3].data = item.count;
          this.MidData[2].data = item.count;
        } else {
          this.stateData[2].data = item.count;
          this.MidData[3].data = item.count;
        }
      });
    },
    closeMask (item) {
      this.showMask = item;
    },
    changeMas (params) {
      this.showDetail(params.id, params.typeof);
    },
    searchMap () {
      // 导入依赖库
      const axios = require('axios');

      // 构建请求URL
      const apiUrl = 'https://api.tianditu.gov.cn/search';
      const apiKey = 'c9cd7951db1ea5b311aae41f5c0d60e4'; // 替换为您的天地图API令牌
      const keyword = '加油站'; // 替换为您要搜索的关键字
      const requestUrl = `${ apiUrl }?tk=${ apiKey }&postStr={"keyWord":"${ keyword }","level":"11","mapBound":"119.92794660422184,29.140862986024614,120.06985339577815,29.23511701397538","queryType":"2","count":"10","start":"0","rand":"0"}`;

      // 发送请求
      axios
        .get(requestUrl)
        .then(response => {
          console.log(response.data.pois); // 处理返回的数据
          this.GasStationPoint = response.data.pois;
        })
        .catch(error => {
          console.error(error); // 处理请求错误
        });
    }
  }
};
</script>

<style lang="scss" scoped>
@function companyW($num) {
  @return ($num * 100)/1920;
}
@function companyH($num) {
  @return ($num * 100)/1080;
}
* {
  font-family: Source Han Sans CN;
  color: #f0f6f5;
  font-size: companyW(20vw);
  box-sizing: border-box;
}
.BottomNav {
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  z-index: 999;
}
.njServe {
  position: relative;
  width: companyW(1920vw);
  height: companyH(1080vh);
  box-sizing: border-box;
  // background-image: url(../../assets/njImgs/njwxBg.png);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  background-color: #1d2531;

  .njServiceTitle {
    position: relative;
    padding-top: companyH(8vh);
    width: companyW(1820vw);
    height: companyH(70vh);
    margin: 0 auto;
    z-index: 999;
  }
  .ContentBox {
    max-width: companyW(1880vw);
    display: flex;
    margin: 0 auto;
    justify-content: space-between;
    z-index: 999;
    .ContentMiddle {
      display: flex;
      flex: 1;
      justify-content: space-between;
      padding: companyH(40vh) companyW(12vw);
      height: 20%;

      .tMap {
        margin-top: companyH(70vh);
        width: 100%;
        height: 100%;
        position: absolute;
        z-index: 1;
        border-radius: 2%;
      }
    }
    .ContentRight {
      width: companyW(460vw);
      height: companyH((980vh));
      overflow: hidden;overflow-y: scroll;

    }
    .ContentRight::-webkit-scrollbar {
  width: 1px;
  /* 设置滚动条的宽度 */
  background-color: transparent;
  /* 设置滚动条的背景色为透明 */
}

.ContentRight::-webkit-scrollbar-thumb {
  background-color: transparent;
  /* 设置滚动条的 thumb 的背景色为透明 */
}
  }
}
.njTopBox {
  box-sizing: border-box;
  width: companyW(460vw);
  // margin-top: companyH(-12vh);
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  background: #03171f;
  background-color: rgba($color: #000000, $alpha: 0.7);
  position: relative;
  z-index: 999;

  .njOne {
    width: companyW(190vw);
    height: companyH(78vh);
    margin: companyH(30vh) companyW(20vw);
    display: flex;
    align-items: center;
    background: #00111c;
    cursor: pointer;
    img {
      width: companyW(39vw);
      height: companyH(39vh);
      margin: companyH(35vh) companyW(20vw);
    }
    .njOne-top {
      max-width: companyW(190vw);
      display: flex;
      align-items: center;
      .njOneFontL {
        max-width: companyW(62.8vw);
        //   overflow: hidden;
        font-size: companyH(22vh);
        font-weight: bold;
        color: #f0f6f5;
        line-height: companyH(17.2vh);
      }
      .njOneFont {
        font-size: companyH(20vh);
        font-weight: Regular;
        color: #f0f6f5;
        line-height: companyH(17.2vh);
      }
    }
    .njOne-bottom {
      font-size: companyH(14vh);
      font-weight: 400;
      color: #b4c1bf;
      margin-top: companyH(9.2vh);
      line-height: companyH(20vh);
    }
  }
  .rightLine {
    position: absolute;
    width: companyW(438vw);
    height: companyH(3vh);
    top: companyH(192vh);
    left: 0;
  }
  .njTopRight {
    z-index: 999;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
    margin: companyH(10vh) companyW(15vw);
    img {
      min-width: companyW(120vw);
      height: companyH(69vh);
    }
    .ztText {
      max-width: companyW(140vw);
      overflow: hidden;
      font-size: companyH(17.2vh);
      font-weight: 400;
      color: #eff0f1;
      line-height: companyH(27vh);
      margin: companyH(12vh) 0;
    }
  }
}
.el-select {
  position: absolute;
  right: companyW(36vw);
  top: companyH(20vh);
  z-index: 3;
  ::v-deep .el-input {
    width: companyW(76vw);
  }
}
::v-deep .el-input__inner {
  height: companyH(27.4vh);
  background: rgba(6, 40, 62, 0.9);
  border-radius: 0;
  border: #00def0 companyW(1vw) solid;
  font-size: companyH(11.35vh);
  font-family: Source Han Sans CN;
  font-weight: 400;
  color: #e1eaea;
}
::v-deep .el-input__suffix-inner {
  position: relative;
  top: companyH(9vh);
}
:global(.el-select-dropdown) {
  width: companyW(76vw);
  background: rgba(6, 40, 62, 0.9);
}
:global(.el-select-dropdown__item span) {
  font-size: companyH(13vh);
}
</style>
