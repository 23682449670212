var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"bottomStyle",style:({
    'background-image': `url(${require('@/assets/' +
      _vm.typeOf +
      '/' +
      _vm.njTitle +
      '.png')}`,
  })},_vm._l((_vm.navData),function(v){return _c('div',{key:v.id,staticClass:"every",on:{"click":function($event){return _vm.changeNav(v.id)}}},[(_vm.currentInd == v.id)?_c('img',{attrs:{"src":require("@/assets/njImgs/currentInd.png"),"alt":""}}):_vm._e(),_vm._v(" "+_vm._s(v.title)+" ")])}),0)
}
var staticRenderFns = []

export { render, staticRenderFns }