const echartsData = {
  color: [ '#fff','#0000000' ],
  city: [ '2020', '2021', '2022', '2023' ],
  legend: [
    {
      name: '维修总量',
    },
    {
      name: '自由维修',
    },
  ],
  data: [
    [ 2, 3, 2, 1 ],
    [ 4, 8, 3, 5 ],
  ],
};
export const njwxOptions = {
  color: [
    '#00C3CD',
    {
      type: 'linear',
      x: 0,
      y: 0,
      x2: 0,
      y2: 1,
      colorStops: [
        {
          offset: 0,
          color: '#00848B', // 0% 处的颜色
        },
        {
          offset: 1,
          color: '#012532', // 100% 处的颜色
        },
      ],
      global: false, // 缺省为 false
    },
    'rgba(255,82,82,0.2)',
  ],
  legend: {
    top: 10,
    left: 'center',
    itemWidth: 20,
    itemHeight: 15,
    itemGap: 50,
    textStyle: {
      fontSize: 16,
      color: '#fff',
    },
    data: echartsData.legend,
  },
  grid: {
    left: '3%',
    right: '4%',
    bottom: '7%',
    containLabel: true,
  },
  xAxis: {
    type: 'category',
    axisLabel: {
      color: '#fff',
      rotate: 0, //倾斜度
    },
    axisLine: {
      lineStyle: {
        color: '#67808A',
      },
      width: 1,
    },
    axisTick: {
      show: false,
    },
    data: echartsData.city,
  },
  yAxis: [
    {
      name: '维修数量',
      nameLocation: 'end',
      type: 'value',
      nameTextStyle: {
        color: '#fff',
      },
      axisLabel: {
        color: '#fff',
      },
      axisLine: {
        show: true,
        lineStyle: {
          color: '#67808A',
        },
      },
      axisTick: {
        show: false,
      },
      splitLine: {
        lineStyle: {
          color: '#67808A',
        },
      },
    },
    {
      type: 'value',
      axisLine: {
        show: true,
        lineStyle: {
          color: '#67808A',
        },
      },
    },
  ],
  series: [],
};
for (var i = 0; i < echartsData.legend.length; i++) {
  njwxOptions.series.push({
    name: echartsData.legend[i].name,
    type: 'bar',
    data: echartsData.data[i],
    barGap: '-100%',
    z: i === 1 ? '1' : '2',
    barWidth: 20,
    itemStyle: {
      normal: {
        borderRadius: 6,
      },
    },
  });
}

export const sqOptions = {
  title: [
    {
      text: '7.01 元/L \n\n加油站：中国石油(余杭塘路加油站)',
      right: '0%',
      top: 'center',
      textStyle: {
        fontWeight: 'normal',
        fontSize: 15,
        color: '#fff',
        itemGap: 5,
      },
    },
  ],
  series: [
    {
      type: 'liquidFill',
      radius: '45%',
      center: [ '30%', '50%' ],
      data: [ 0.5, 0.5, 0.5 ], // data个数代表波浪数
      backgroundStyle: {
        borderWidth: 1,
        color: 'rgb(255,0,255,0.1)',
      },
      label: {
        normal: {
          formatter: '最低油价',
          // color: "blue",
          textStyle: {
            fontSize: 20,
            color: '#fff',
          },
        },
      },
      outline: {
        show: false,
      },
    },
    {
      type: 'pie',
      center: [ '30%', '50%' ],
      radius: [ '50%', '52%' ],
      hoverAnimation: false,
      data: [
        {
          name: '',
          value: 500,
          labelLine: {
            show: false,
          },
          itemStyle: {
            color: '#009cff',
          },
          emphasis: {
            labelLine: {
              show: false,
            },
            itemStyle: {
              color: '#009cff',
            },
          },
        },
        {
          //画中间的图标
          name: '',
          value: 4,
          labelLine: {
            show: false,
          },
          itemStyle: {
            color: '#ffffff',
            normal: {
              color: '#009cff',
              borderColor: '#009cff',
              borderWidth: 10,
              // borderRadius: '100%'
            },
          },
          label: {
            borderRadius: '100%',
          },
          emphasis: {
            labelLine: {
              show: false,
            },
            itemStyle: {
              color: '#009cff',
            },
          },
        },
        {
          // 解决圆点过大后续部分显示明显的问题
          name: '',
          value: 4,
          labelLine: {
            show: false,
          },
          itemStyle: {
            color: '#009cff',
          },
          emphasis: {
            labelLine: {
              show: false,
            },
            itemStyle: {
              color: '#009cff',
            },
          },
        },
        {
          //画剩余的刻度圆环
          name: '',
          value: 88,
          itemStyle: {
            color: 'rgba(9,59,118,0.95)',
          },
          label: {
            show: false,
          },
          labelLine: {
            show: false,
          },
          emphasis: {
            labelLine: {
              show: false,
            },
            itemStyle: {
              color: 'rgba(255,255,255,0)',
            },
          },
        },
      ],
    },
  ],
};
