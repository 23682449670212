<template>
  <div class="RightBox">
    <div class="rightLogo">{{ repairStatus2 }}</div>
    <div class="titleRight">
      维修机械：<span>{{ ListDat.machineryType }}</span>
    </div>
    <div class="titleRight">
      维修问题：<span>{{ ListDat.repairRemark }}</span>
    </div>
    <div class="titleRight">
      农具所有人：<span>{{ ListDat.carOwner }}</span>
    </div>
    <div class="titleRight">
      维修时间：<span>{{ ListDat.updateTime || '待维修' }}</span>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    ListDat: {
      type: Object,
      default: () => {}
    }
  },
  data () {
    return { repairStatus2: '' };
  },
  mounted () {
    this.repairStatus1();
  },
  methods: {
    repairStatus1 () {
      if (this.ListDat.repairStatus === 1) {
        this.repairStatus2 = '待维修';
      } else if (this.ListDat.repairStatus === 2) {
        this.repairStatus2 = '正在维修';
      } else if (this.ListDat.repairStatus === 3) {
        this.repairStatus2 = '已完成';
      } else if (this.ListDat.repairStatus === 4) {
        this.repairStatus2 = '已取消';
      }
    }
  }
};
</script>

<style lang="scss" scoped>
@function companyW($num) {
  @return ($num * 100)/1920;
}
@function companyH($num) {
  @return ($num * 100)/1080;
}
* {
  font-family: Source Han Sans CN;
  color: #f0f6f5;
  font-size: companyW(20vw);
  box-sizing: border-box;
}
.RightBox {
  margin-bottom: 10px;
  width: companyW(420vw);
  background: #00aaff23;
  position: relative;
  padding: companyH(18vh) companyW(17vw);
  display: flex;
  flex-direction: column;
  .titleRight {
    font-size: companyH(16vh);
    font-weight: Regular;
    margin: companyH(15vh) 0;
    color: #ffffff92;
    span {
      color: #fff;
      font-size: companyH(16vh);
    }
  }
  .Btn {
    width: companyW(361vw);
    height: companyH(31vh);
    background: rgba(55, 57, 66, 0.54);
    border: companyW(1vw) solid #00a8ff;
    text-align: center;
    line-height: companyH(31vh);
    color: #00a8ff;
    margin: companyH(10vh) auto;
    font-size: companyH(16vh);
  }
  .rightLogo {
    position: absolute;
    right: 0;
    top: 0;
    width: companyW(80vw);
    background: linear-gradient(90deg, #ed3f3f, #ef8564);
    opacity: 0.8;
    border-radius: companyW(3vw);
    text-align: center;
    font-size: companyH(16vh);
    line-height: companyH(30vh);
  }
}
</style>
