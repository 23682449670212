<template>
  <div>
    <el-dialog :visible.sync="chaMask" :show-close="false" @close="closeMask">
      <dv-border-box-1>
        <div class="modelTltle">{{ modelTitle }}</div>
        <div class="close" @click="closeMask"></div>

        <div class="toptitletop">
          <div class="toptitletitle">维修站点</div>
          <div class="toptitletitle">维修记录</div>
        </div>
        <div class="diat">
          <div class="ToolBox">
            <el-table
              height="500"
              stripe
              v-loading="tabLoading"
              element-loading-text="拼命加载中"
              element-loading-spinner="el-icon-loading"
              element-loading-background="rgba(0, 0, 0, 0.8)"
              :data="TanleContent"
              @row-click="openDetails"

            >
              <el-table-column
                type="index"
                label="序号"
                align="center"
                width="60"
              >
              </el-table-column>
              <el-table-column prop="shopName" label="维修点名称" width="150">
              </el-table-column>
              <el-table-column
                prop="carBrand"
                label="位置"
                align="center"
                width="240"
              >
                <template v-slot="{ row }">
                  <div style="font-size: 14px">
                    {{ `${row.province}${row.city}${row.area} ${row.address}` }}
                  </div>
                </template>
              </el-table-column>
              <el-table-column
                prop="shopPeople"
                label="联系人"
                width="120 "
                align="center"
              >
              </el-table-column>

              <el-table-column
                prop="shopTel"
                label="手机号码"
                width="120 "
                align="center"
              >
              </el-table-column>

              <div slot="empty" style="font-size: 20px; color: #fff">
                暂无维修站点
              </div>
            </el-table>
          </div>
          <div class="rightDbox">
            <div class="rightbox" v-for="item in infoInfo" :key="item.id">
              <div style="display: flex; margin-top: 10px">
                <div class="yezhu">订单业主: {{ item.carOwner }}</div>
                <div class="jixie">维修机械: {{ item.machineryType }}</div>
              </div>
              <div class="wenti">维修问题:{{ item.repairRemark }}</div>
              <div style="display: flex; margin-top: 20px">
                <div class="jixie">维修时间: {{ item.createTime }}</div>
              </div>
            </div>
          </div>
        </div>
      </dv-border-box-1>
    </el-dialog>
  </div>
</template>

<script>
import { getMachinery } from '@/api/njApi/njwx.js';
// import * as options from "@/views/njall/mock/njToolMock.js";
export default {
  components: {},
  props: {
    tableTotal: {
      type: Number,
      default: 0
    },
    changeMask: {
      type: Boolean,
      default: false
    },

    TanleContent: {
      type: Array,
      default: () => []
    },
    modelTitle: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      currentPage: 1,
      pageSize: 10,
      tabLoading: true,
      currentInd: 1,
      // ...options,
      chaMask: false,
      rateColor: [ '#009ad6' ],
      mspMask: false,
      mapInfo: {},
      infoInfo: {}
    };
  },
  watch: {
    changeMask: {
      handler (newv, oldv) {
        this.chaMask = newv;
      }
    }
  },

  methods: {
    openDetails (row) {
      console.log(row);
      this.getMachineryy(row.id);
    },
    async getMachineryy (row) {
      const { data } = await getMachinery({
        pageNum: this.currentPage,
        pageSize: this.pageSize,
        shopId: row
      });
      console.log(data);
      this.infoInfo = data.records;
    },
    open (row) {
      console.log(row, 'asdasdas');
      this.mapInfo = row;
      this.mspMask = true;
    },
    closeMapMask () {
      this.mspMask = false;
      this.mapInfo = null;
    },
    //弹窗改变
    currentChange (e) {
      this.tabLoading = true;
      this.$listeners.getTabal(e);
      setTimeout(() => {
        this.tabLoading = false;
      }, 1000);
    },
    closeMask () {
      this.currentPage = 1;
      this.chaMask = false;
      this.$emit('getMask', false);
    },

    changeNav (id) {
      this.tabLoading = true;
      this.currentInd = id;
      setTimeout(() => {
        this.tabLoading = false;
      }, 1000);
    },
    ent () {}
  },
  mounted () {
    setTimeout(() => {
      this.tabLoading = false;
    }, 1000);
    console.log(this.chaMask);
  }
};
</script>

<style lang="scss" scoped>
@function companyW($num) {
  @return ($num * 100)/1920;
}
@function companyH($num) {
  @return ($num * 100)/1080;
}
* {
  font-family: Source Han Sans CN;
  font-size: companyW(20vw);
  box-sizing: border-box;
}
::v-deep .el-dialog {
  width: companyW(1350vw);

  background-color: rgba(#000, 0.9);
}
::v-deep .el-tabs--left .el-tabs__item.is-left {
  text-align: left;
}
::v-deep .el-dialog__body {
  padding: 0;
}
.modelTltle {
  width: 100%;
  height: companyH(70vh);
  font-size: companyW(40vw);
  color: rgb(1, 148, 226);
  line-height: companyH(70vh);
  text-align: center;
}
.close {
  width: companyW(40vw);
  height: companyW(40vw);
  background: url('../../../assets/close.png');
  background-size: 100% 100%;
  background-repeat: no-repeat;
  position: absolute;
  top: companyH(20vh);
  right: companyW(35vw);
}
.ToolBox {
 width: companyW(800vw);
  // height: companyH(605vh);
  margin: 0 auto;
  padding: 0 0 companyH(10vh);
  display: flex;
}

.toptitletop {
  display: flex;
  justify-content: space-between;
  padding-left: companyW(50vw);
  padding-right: companyW(450vw);
  padding-bottom: 10px;

  .toptitletitle {
    font-weight: 400;
    font-size: 18px;
    color: #71cdf9;
  }
}
.diat {
  padding: 0 80px 0 20px;
  display: flex;
  justify-content: start;
}

.rightDbox {
  width: companyW(450vw);
  height: companyH(500vh);
  overflow-x: hidden;
  overflow-y: scroll;
  display: flex;
  flex-wrap: wrap;
}
::-webkit-scrollbar {
  // 纵向滚动条和横向滚动条宽度
  width: companyW(10vw);
  height: companyH(10vh);
}
::-webkit-scrollbar-thumb {
  // 滚动条背景条样式
  border-radius: 1px;
  -webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
  background: #fff;
}
::-webkit-scrollbar-track {
  // 滚动条按钮样式
  -webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
  border-radius: 1px;
  background: #5b5f63;
}
.rightbox {
  width: companyW(420vw);
  height: companyH(156vh);
  background: #0d171c;
  padding-left: companyW(17vw);
  margin-bottom: 10px;
  .wenti {
    font-weight: 400;
    font-size: companyW(14vw);
    color: #fff9f8;
  }
  .yezhu {
    width: companyW(160vw);

    font-weight: 400;
    font-size: companyW(14vw);
    color: #fff9f8;

    height: companyH(50vh);
  }
  .jixie {
    width: companyW(230vw);
    font-weight: 400;
    font-size: companyW(14vw);
    color: #fff9f8;
  }
}
::v-deep .el-table th {
  background: #1c65b8;
  border-top: companyW(1vw) solid #1c65b8;
  border-bottom: companyW(1vw) solid #1c65b8;
  height: companyH(35vh);
  padding: 0;
  text-align: center;
}
::v-deep .cell {
  font-size: companyH(14vh);
  font-family: Source Han Sans SC;
  font-weight: 400;
  color: #fff;
}
::v-deep .el-table,
.el-table__expanded-cell {
  background-color: rgba(255, 255, 255, 0);
}
::v-deep .el-table td,
.building-top .el-table th.is-leaf {
  border-bottom: 1px solid rgba(238, 238, 238, 0);
}
::v-deep .el-table--striped .el-table__body tr.el-table__row--striped td {
  background: #71cef916;
}
::v-deep .el-dialog__header {
  padding: 0;
}
::v-deep .el-table tr,
::v-deep .el-table::before,
::v-deep .el-table--enable-row-hover .el-table__body tr:hover > td {
  background: #000000;
}
.toolStyle {
  color: #fff !important;
  font-size: companyH(14vh);
  text-decoration: underline;
  cursor: pointer;
}

.sz-pagination {
  margin: companyH(10vh) 0;
  height: companyH(80vh);
  text-align: center;
  width: 100%;
  .fy-slot {
    margin-right: companyW(10vw);
    color: #1c65b8;
  }

  .ya {
    text-align: center;
    margin-right: companyW(7vw);
    display: inline-block;
    width: companyW(35vw);
    height: companyH(30vh);
    border-radius: 5px;
    font-weight: bold;
    background-color: #4e6415;
  }
  .yang {
    text-align: center;
    margin-right: companyW(7vw);
    display: inline-block;
    width: companyW(35vw);
    height: companyH(30vh);
    border-radius: 5px;
    font-weight: bold;
    background-color: #1b826f;
  }
  .ckdz {
    color: #1c65b8;
    text-decoration: underline;
  }
  ::v-deep {
    .btn-prev {
      background: #000;
    }
    .el-pager {
      background: #000;
      color: #1c65b8;

      .number {
        background: #000;
        border: 1px solid #1c65b8;
      }
      .number:hover {
        color: #fff;
      }
    }
    .btn-next {
      background-color: black;
    }
    .el-icon-arrow-right {
      background-color: black;
    }
    .el-icon-arrow-left:before {
      color: #1c65b8;
      border: 1px solid #1c65b8;
    }
    .el-icon-arrow-right:before {
      color: #1c65b8;
      border: 1px solid #1c65b8;
    }
    .active {
      color: #fff;
      font-weight: 400;
    }
    .el-pager li.btn-quicknext,
    .el-pager li.btn-quickprev {
      background-color: #000;
      color: #1c65b8;
      border: 1px solid #1c65b8;
    }
  }
}
</style>
