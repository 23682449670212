<template>
  <div>
  <div class="leftImg">
    <img :src=getimg() alt="" />
    <div class="tiBox">
    <div>农机状态：{{ stateParams.title }}</div>
    <div>{{ stateParams.count }}台</div>
    </div>
</div>
 </div>
</template>

<script>
export default {
  props: {
    stateParams: {
      type: Object,
      default: () => {},
    },
    uid:{
      type:Number
    }
  },
  methods:{
    getimg(){
      if(this.uid===1)return require('@/assets/njImgs/maskCount.png');
      else if(this.uid===2)return require('@/assets/njImgs/maskcount_orange.png');
      else if(this.uid===3)return require('@/assets/njImgs/maskCount_white.png');
      else if(this.uid===4)return require('@/assets/njImgs/maskCount_green.png');
      else if(this.uid===5)return require('@/assets/njImgs/maskCount_blue.png');
      else return require('@/assets/njImgs/maskCount_puple.png');
    }
  }
};
</script>

<style lang="scss" scoped>
@function companyW($num) {
  @return ($num * 100)/1920;
}
@function companyH($num) {
  @return ($num * 100)/1080;
}
.leftImg {
  width: companyW(540vw);
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  img {
    width: companyW(578vw);
    height: companyH(590vh);
  }
  .tiBox {
    position: absolute;
    left: companyW(177vw);
    display: flex;
    flex-direction: column;
    div:nth-child(1) {
      font-size: companyH(22vh);
      font-family: Source Han Sans CN;
      font-weight: bold;
      color: #ffffff;
      margin-bottom: companyH(47vh);
      margin-top: companyH(106vh);
    }
    div:nth-child(2) {
      font-size: companyH(70vh);
      font-family: DIN;
      font-weight: 400;
      color: #ffffff;
      text-shadow: 1px 1px 1px #33f8f8;
      -webkit-text-stroke: 1px #fff;
      -webkit-filter: drop-shadow(1px 1px 0px #33f8f8);
    }
  }
}
</style>
