<template>
  <div
    class="bottomStyle"
    :style="{
      'background-image': `url(${require('@/assets/' +
        typeOf +
        '/' +
        njTitle +
        '.png')}`,
    }"
  >
    <div
      v-for="v in navData"
      :key="v.id"
      class="every"
      @click="changeNav(v.id)"
    >
      <img
        v-if="currentInd == v.id"
        src="@/assets/njImgs/currentInd.png"
        alt=""
      />
      {{ v.title }}
    </div>
  </div>
</template>

<script>
export default {
  props: {
    navData: {
      type: Array,
      default: () => [],
    },
    typeOf: {
      type: String,
      default: '',
    },
    njTitle: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      currentInd: 'njserve',
    };
  },
  methods: {
    changeNav(ind) {
      if (this.currentInd == ind) return;
      this.currentInd = ind;
      this.$router.push({
        path: `/${ ind }`,
      });
    },
  },
  mounted() {
    console.log(this.$route);
    this.currentInd = this.$route.name;
  },
};
</script>

<style lang="scss" scoped>
@function companyW($num) {
  @return ($num * 100)/1920;
}
@function companyH($num) {
  @return ($num * 100)/1080;
}
* {
  font-family: Source Han Sans CN;
  color: #f0f6f5;
  font-size: companyW(20vw);
  box-sizing: border-box;
}
.bottomStyle {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  align-items: center;
  width: companyW(814vw);
  height: companyH(79vh);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1;
  .every {
    // width: companyW(62vw);
    line-height: companyH(48vh);
    font-size: companyH(20vh);
    text-align: center;
    margin: 0 companyW(25vw) 0 companyW(25vw);
    font-weight: bold;
    color: rgba(255, 255, 255, 0.8);
    position: relative;
    z-index: 2;
    cursor: pointer;
    img {
      width: companyW(148vw);
      height: companyH(52vh);
      position: absolute;
      top: companyH(-10vh);
      left: companyW(-36vw);
      opacity: 0.8;
      z-index: -1;
    }
  }
}
</style>
